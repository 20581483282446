.notifications {
  position: absolute;

  transform: translate(-45%, -65%);
  -ms-transform: translate(-45%, -65%);
  background-color: #f35d5d !important;
  color: #ffffff;
  /* font-size: 16px; */
  /* padding: 12px 24px; */
  border: none;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
  width: auto;
  height: 63%;
  padding-left: 4.2%;
}
